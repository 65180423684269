<template>
  <div class="schedule-holder bgcolor-gold">
    <div class="schedule-header">Schedule</div>

    <div class="sera-date">Wednesday, March 19</div>
    <div class="sgp-content-block">
      <div class="sgp-title-label">
        Arrival Day
      </div>
      <p class="v4-body-paragraph color-storm">
        Settle in and enjoy your stay, then head to the Palm Beach International Boat Show for a glimpse of the finest yachts on display.
      </p>
    </div>

    <hr>

    <div class="sera-date">Thursday, March 20</div>
    <div class="sgp-content-block">
      <div class="sgp-title-label">
        <img src="../../../../assets/images/arrow-next-frenchblue.svg"> <span>Activities</span>
      </div>
      <p class="v4-body-paragraph color-storm">
        Spend the day enjoying your chosen adventure:
      </p>

      <div class="schedule-header color-storm">Golfing Tourney</div>
      <p class="v4-body-paragraph color-storm">
        Tee off at The Breakers' iconic course for 18 holes.
      </p>

      <div class="schedule-header color-storm">Fishing Tourney</div>
      <p class="v4-body-paragraph color-storm">
        Reel in the excitement with some of the best sportfishing species.
      </p>

      <div class="schedule-header color-storm">Yachting Tourney</div>
      <p class="v4-body-paragraph color-storm">
        Embrace the spirit of seamanship, dress the part, act the part and enjoy the camaraderie on the water.
      </p>

      <div class="sgp-time-label">6PM-8PM</div>
      <div class="sgp-title-label">
        <img src="../../../../assets/images/arrow-next-frenchblue.svg"> <span>Mixer at Sailfish Club</span>
      </div>
      <p class="v4-body-paragraph color-storm">
        Join us at the Sailfish Club for a cocktail party on the lawn.
      </p>

      <hr>

      <div class="sera-date" style="margin-bottom: 0.5em;">Friday, March 21</div>
      <div class="sgp-title-label">
        <img src="../../../../assets/images/arrow-next-frenchblue.svg"> <span>Departure Day</span>
      </div>
      <p class="v4-body-paragraph color-storm">
        Enjoy breakfast before heading to the Palm Beach International Boat Show for a glimpse of the finest yachts on display.
      </p>
    </div>

  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">

</style>