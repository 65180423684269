<template>

  <div class="layout-2-3col">

    <!-- LEFT COL -->
    <div class="col-30">
      <!-- Intro -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Only the best</div>
        <p class="v4-base-paragraph color-storm">
          We have chosen the two best hotels in Palm Beach and secured rooms for the Escape. Please let us know which hotel you prefer.
          <br>
          <br>
          Limited Availability.
        </p>
      </div>
    </div>

    <!-- RIGHT COL -->
    <div class="col-60">
      <!-- The Breakers -->
      <div class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">The Breakers</div>
        <div class="destination-content">
          <div class="left">
            <div class="v4-section-header-light color-navy">Deluxe Guest Room with Partial Ocean View</div>
            <p class="v4-base-paragraph color-white-cap">
              This charming guest room offers striking views of the resort grounds and ocean, complemented by elegant furnishings that embody Palm Beach's allure. The luxe bathroom adds a modern touch with a marble-trimmed shower, a TV-embedded mirror, and sleek details.
            </p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">Double Occupancy / $1,625 / Night</li>
            </ul>
          </div>
          <div class="right">
            <a href="https://www.thebreakers.com/" target="_blank">
              <img src="https://cloud.bartonandgray.com/library/activities/events/pb-2025/breakers-pb-logo.png" alt="">
            </a>
            <div class="date-option" :class="isSelected('The Breakers')" @click="toggleSelected('The Breakers')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">SELECT THE BREAKERS</div>
            </div>
          </div>
        </div>
      </div>

      <!-- White Elephant Palm Beach -->
      <div class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">White Elephant Palm Beach</div>
        <div class="destination-content">
          <div class="left">
            <div class="v4-section-header-light color-navy">Deluxe King Room</div>
            <p class="v4-base-paragraph color-white-cap">
              The White Elephant Palm Beach offers deluxe rooms designed for both comfort and style, featuring a king bed, TV, chair, desk, and a small refrigerator. The connecting bathroom adds a touch of luxury with its vanity, tiled shower, and sleek chrome fixtures.
            </p>
            <ul class="v4-bullet-list not-italic">
              <li class="color-gold">Double Occupancy / $1,250 / Night</li>
            </ul>
          </div>
          <div class="right">
            <a href="https://www.whiteelephantpalmbeach.com/" target="_blank">
              <img src="https://cloud.bartonandgray.com/library/activities/events/pb-2025/white-elephant-logo-2.png" alt="">
            </a>
            <div class="date-option" :class="isSelected('White Elephant Palm Beach')" @click="toggleSelected('White Elephant Palm Beach')">
              <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4913_45809)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_4913_45809">
                    <rect width="35" height="35" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="v4-base-header-nova color-gradient-shark">SELECT WHITE ELEPHANT PALM BEACH</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Button -->
      <div @click="$emit('close', selectedDates)" style="width: 400px; max-width: 100%; margin: 3em 0; margin-left: auto; padding-right: 1.5em">
        <div class="v4-download-button bg-color-gradient-cloud">
          <div class="soft-square bg-color-gradient-sky">
            <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
          </div>
          <div class="button-label color-gradient-shark">NEXT: ACTIVITIES &AMP; CUISINE</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const props = defineProps(['accommodations']);

  let selectedAccommodations = ref(props.accommodations);

  function toggleSelected(selection){
    if (selectedAccommodations.value.includes(selection)) {
      selectedAccommodations.value.splice(selectedAccommodations.value.indexOf(selection), 1);
    } else {
      selectedAccommodations.value.push(selection);
    }
  }

  function isSelected(selection){
    if(selectedAccommodations.value.includes(selection)){
      return 'selected';
    } else {
      return;
    }
  }
</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .checkmark{
    path{
      fill: $v3-french-blue;
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
  }

  .destination-wrapper{
    border: 1px solid black;
    margin-bottom: 2em;
    .v4-section-header-heavy{
      background: $gradient-shark !important;
      padding: 0.45em 0 0.35em 0.75em;
    }
  }

  .destination-content{
    background: $gradient-sky !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.5em;
    padding-left: 2em;
    padding-bottom: 2em;
    padding: 1.5em 2em 2em 2em;
    @media only screen and (max-width: 1281px){
      flex-direction: column;
    }
    .right{
      @media only screen and (min-width: 1280px){
        padding-left: 1em;
      }
      img{
        width: 400px;
        max-width: 100%;
        margin-bottom: 1em;
      }
    }
    .date-option{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      background: $gradient-cloud !important;
      border: 1px dashed black;
      width: 400px;
      max-width: 100%;
      margin-bottom: 1.5em;
      padding: 0.75em 0 0.75em 1em;
      transition: 0.15s ease-in-out;
      &:hover{
        filter: brightness(90%);
        cursor: pointer;
      }
      &.selected{
        path{
          fill: $v3-port;
        }
      }
    }
  }

  .previous-step-button{
    width: 400px;
    max-width: 100%;
    margin: 3em 0;
    margin-right: auto;
    padding-right: 1.5em;

    @media only screen and (max-width: 1280px){
      margin-left: 1em;
    }
  }

  .v4-download-button{
    user-select: none;
    &.inactive{
      filter: brightness(80%);
    }
  }
</style>