<template>

  <div class="layout-2col flex-col-reverse-mobile">

    <!-- LEFT COL -->
    <div class="col-50">
      <!-- Intro -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Something for everyone</div>
        <p class="v4-base-paragraph color-storm" style="width: 450px; max-width: 100%;">Whether you fancy chasing sportfish offshore, a round of golf, or a leisurely cruise, not to mention the meals and parties - there is plenty to do in PB at the 2025 Escape.</p>
      </div>

      <div class="sportfishing-cruising-card bgcolor-cruising">
        <div class="v3-intro-header color-gradient-shark enforce-sh-large">PALM BEACH SPORTFISHING</div>
        <hr>
        <p class="v4-body-paragraph" style="margin-top: 0;">
          The deep, clear waters off Palm Beach are rich with trophies, offering some of the best sportfishing experiences around. Cast your line and chase the thrill of reeling in prized catches in this world-class fishing destination.
        </p>

        <div class="v3-intro-header color-gradient-shark enforce-sh-large">THE SPECIES</div>
        <hr>
        <div class="species-list">
          <div>
            <img src="https://cloud.bartonandgray.com/library/sportfishing/popular-species/2024/tarpon-01.png" style="width: 211px;" alt="Tarpon Art">
            <div class="sera-brand-header color-navy" style="text-align: center;">Tarpon</div>
          </div>
          <div>
            <img src="https://cloud.bartonandgray.com/library/sportfishing/popular-species/2024/sailfish-1.png" style="width: 225px;" alt="Sailfish Art">
            <div class="sera-brand-header color-navy" style="text-align: center;">Sailfish</div>
          </div>
          <div>
            <img src="https://cloud.bartonandgray.com/library/sportfishing/popular-species/2024/mahi-mahi-01.png" style="width: 245px;" alt="Mahi Mahi Art">
            <div class="sera-brand-header color-navy" style="text-align: center;">Mahi Mahi</div>
          </div>
          <div>
            <img src="https://cloud.bartonandgray.com/library/sportfishing/popular-species/2024/blue-marlin-01.png" style="width: 208px" alt="Blue Marlin Art">
            <div class="sera-brand-header color-navy" style="text-align: center;">Blue Marlin</div>
          </div>
          <div>
            <img src="https://cloud.bartonandgray.com/library/sportfishing/popular-species/2024/wahoo-01.png" style="width: 211px;" alt="Wahoo Art">
            <div class="sera-brand-header color-navy" style="text-align: center;">Wahoo</div>
          </div>
          <div>
            <img src="https://cloud.bartonandgray.com/library/sportfishing/popular-species/2024/shark-01.png" style="width: 223px;" alt="Shark Art">
            <div class="sera-brand-header color-navy" style="text-align: center;">Shark</div>
          </div>
        </div>
      </div>

      <div style="margin-top: 2em;">
        <img src="https://cloud.bartonandgray.com/library/activities/events/pb-2025/breakers-ocean-course.png" style="width: 720px; max-width: 100%;" alt="Course map for Breakers Ocean Golf Course">
        <div class="content-wrapper" style="width: 600px; max-width: 100%;">
          <div style="text-align: left; letter-spacing: 0.07em;" class="v3-intro-header color-gradient-shark enforce-sh-large">THE BREAKERS OCEAN COURSE</div>
          <hr>
          <p class="v4-body-paragraph" style="font-weight: 300; line-height: 28px; letter-spacing: 0.72px;">
            Recognized as Florida's oldest golf course, The Breakers Ocean Course was originally designed by Alexander H. Findlay in 1897. It underwent a comprehensive renovation by renowned architect Rees Jones, reopening in late 2018. 
            <br>
            <br>
            Par-70 course<br>
            5,778 yards <br>
            TifEagle Bermuda greens<br>
            56 sand and grass-faced bunkers<br>
            6 lakes<br>
            <br>
            The design emphasizes strategic play, rewarding finesse over power, and offers occasional glimpses of the Atlantic Ocean.
          </p>
        </div>
      </div>

      <div class="mobile-only" @click="$emit('close', selectedTourneys)" style="width: 400px; max-width: 100%; margin: 3em 0; margin-left: auto; padding-right: 1.5em">
        <div class="v4-download-button bg-color-gradient-cloud">
          <div class="soft-square bg-color-gradient-sky">
            <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
          </div>
          <div class="button-label color-gradient-shark">NEXT: CONTACT INFORMATION</div>
        </div>
      </div>
    </div>

    <!-- RIGHT COL -->
    <div class="col-50">
      <!-- The Tourneys -->
      <div class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">The Tourneys</div>
        <div class="destination-content">
          <ul class="v4-bullet-list not-italic">
            <li class="color-gold">$300 PER PERSON / ONE PER PERSON</li>
          </ul>

          <div class="date-option" :class="isSelected('Golf Tourney')" @click="toggleSelectedWithType('Tourney', 'Golf Tourney')">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">GOLF TOURNEY</div>
          </div>

          <p class="v4-base-paragraph color-white-cap">
            Tee off at The Breakers' iconic course for 18 holes.
          </p>

          <div class="date-option" :class="isSelected('Fishing Tourney')" @click="toggleSelectedWithType('Tourney', 'Fishing Tourney')">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">FISHING TOURNEY</div>
          </div>

          <p class="v4-base-paragraph color-white-cap">
            Reel in the excitement with some of the best sportfishing species followed by a cook your catch lunch.
          </p>

          <div class="date-option" :class="isSelected('Yachting Tourney')" @click="toggleSelectedWithType('Tourney', 'Yachting Tourney')">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">YACHTING TOURNEY</div>
          </div>

          <p class="v4-base-paragraph color-white-cap">
            Embrace the spirit of seamanship, dress the part, act the part and enjoy the camaraderie on the water.
          </p>

        </div>
      </div>

      <!-- Parties & Meals -->
      <div class="destination-wrapper">
        <div class="v4-section-header-heavy color-gold">Socialize &amp; Celebrate</div>
        <div class="destination-content">
          <ul class="v4-bullet-list not-italic">
            <li class="color-gold">ALL INCLUDED / CHOOSE ANY OR ALL</li>
          </ul>

          <div class="date-option" :class="isSelected('Sailfish Club Social')" @click="toggleSelectedWithType('Party', 'Sailfish Club Social')">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">SAILFISH CLUB SOCIAL</div>
          </div>

          <p class="v4-base-paragraph color-white-cap">
            Join us at the Sailfish Club for a cocktail party on the lawn with fellow Members and friends. (Thurs)
          </p>

          <div class="date-option" :class="isSelected('The Palm Beach Boat Show')" @click="toggleSelectedWithType('Party', 'The Palm Beach Boat Show')">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">THE PALM BEACH BOAT SHOW</div>
          </div>

          <p class="v4-base-paragraph color-white-cap">
            Head to the Palm Beach International Boat Show for a glimpse of the finest yachts on display. (Weds and/or Fri)
          </p>

          <div class="date-option" :class="isSelected('Cook Your Catch Lunch')" @click="toggleSelectedWithType('Party', 'Cook Your Catch Lunch')">
            <svg class="checkmark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4913_45809)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_4913_45809">
                  <rect width="35" height="35" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div class="v4-base-header-nova color-gradient-shark">COOK YOUR CATCH LUNCH</div>
          </div>

          <p class="v4-base-paragraph color-white-cap">
            After your selected tourney, join fellow Members for a delicious gathering where we'll cook up the fresh catch from the fishing tourney and share the day's stories! (Thurs)
          </p>

        </div>
      </div>

      <!-- Button -->
      <div class="desktop-only" @click="$emit('close', selectedTourneys)" style="width: 400px; max-width: 100%; margin: 3em 0; margin-left: auto; padding-right: 1.5em">
        <div class="v4-download-button bg-color-gradient-cloud">
          <div class="soft-square bg-color-gradient-sky">
            <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
          </div>
          <div class="button-label color-gradient-shark">NEXT: CONTACT INFORMATION</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const props = defineProps(['tourneys', 'parties']);

  let selectedTourneys = ref(props.tourneys);
  let selectedParties = ref(props.parties);

  function toggleSelected(selection){
    if (selectedTourneys.value.includes(selection)) {
      selectedTourneys.value.splice(selectedTourneys.value.indexOf(selection), 1);
    } else {
      selectedTourneys.value.push(selection);
    }
  }

  function toggleSelectedWithType(type, selection){
    switch (type) {
      case 'Tourney':
        if (selectedTourneys.value.includes(selection)) {
          selectedTourneys.value.splice(selectedTourneys.value.indexOf(selection), 1);
        } else {
          selectedTourneys.value.push(selection);
        }
        break;
      case 'Party':
        if (selectedParties.value.includes(selection)) {
          selectedParties.value.splice(selectedParties.value.indexOf(selection), 1);
        } else {
          selectedParties.value.push(selection);
        }
        break;
    
      default:
        break;
    }
  }

  function isSelected(selection){
    if(selectedTourneys.value.includes(selection) || selectedParties.value.includes(selection)){
      return 'selected';
    } else {
      return;
    }
  }
</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .checkmark{
    path{
      fill: $v3-french-blue;
    }
    &.selected{
      path{
        fill: $v3-port;
      }
    }
  }

  .destination-wrapper{
    border: 1px solid black;
    margin-bottom: 2em;
    .v4-section-header-heavy{
      background: $gradient-shark !important;
      padding: 0.45em 0 0.35em 0.75em;
    }
  }

  .destination-content{
    background: $gradient-sky !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.5em;
    padding-left: 2em;
    padding-bottom: 2em;
    padding: 1.5em 2em 2em 2em;
    @media only screen and (max-width: 1281px){
      flex-direction: column;
    }

    .v4-base-paragraph{
      margin-bottom: 1em;
    }

    .right{
      @media only screen and (min-width: 1280px){
        padding-left: 1em;
      }
      img{
        width: 400px;
        max-width: 100%;
        margin-bottom: 1em;
      }
    }
    .date-option{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      background: $gradient-cloud !important;
      border: 1px dashed black;
      width: 450px;
      max-width: 100%;
      margin-bottom: 1.5em;
      padding: 0.75em 0 0.75em 1em;
      transition: 0.15s ease-in-out;
      &:hover{
        filter: brightness(90%);
        cursor: pointer;
      }
      &.selected{
        path{
          fill: $v3-port;
        }
      }
    }
  }

  .previous-step-button{
    width: 400px;
    max-width: 100%;
    margin: 3em 0;
    margin-right: auto;
    padding-right: 1.5em;

    @media only screen and (max-width: 1280px){
      margin-left: 1em;
    }
  }

  .sportfishing-cruising-card{
    width: 680px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 42px;
    padding: 2em;

    .species-list{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      align-items: flex-end;
      justify-content: center;

      @media only screen and (max-width: 600px){
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-row-gap: 2em;
      }

      div{
        margin: 0 auto;
      }

      .v3-base-paragraph{
        text-align: center;
      }
    }

  }

  .v4-base-header-nova{
    user-select: none;
  }

  .v4-download-button{
    user-select: none;
    &.inactive{
      filter: brightness(80%);
    }
  }
</style>