<!--
  Author: dhersey
  Date: 01/22/2025
  Desc: New form project for Palm Beach Member Escape event. 
        Basically the same thing as BgPlanVoyage

-->

<template>

  <!-- TESTING -->
  <div v-if="isDebug" style="border: 1px dashed black; padding: 0.5em;">
    currentStep: {{ currentStep }}<br>
    Accommodations: {{ selectedAccommodations }}<br>
    Tourneys: {{ selectedTourneys }}<br>
    Parties: {{ selectedParties }}<br>
    UserData: {{ userData }}
  </div>

  <div class="navigation-wrapper">
    <pb-form-tracker 
      :step="currentStep"
      @changeStep="(i) => changeStep(i)"/>
  </div>

  <!-- 0 - Overview -->
  <section class="overview-wrapper" :class="isActiveStep(0)">
    <pb-overview 
      @close="(i) => changeStep(1)"/>
  </section>

  <!-- 1 - Accomodations -->
  <section class="accommodations-wrapper" :class="isActiveStep(1)">
    <pb-accommodations 
      :accommodations="selectedAccommodations"
      @close="(i) => changeStep(2)"
      @changeStep="(i) => changeStep(i)"/>
  </section>

  <!-- 2 - Activities -->
  <section class="activities-wrapper" :class="isActiveStep(2)">
    <pb-activities
      :accommodations="selectedAccommodations"
      :tourneys="selectedTourneys"
      :parties="selectedParties"
      @close="(i) => changeStep(3)"
      @changeStep="(i) => changeStep(i)"
    />
  </section>

  <!-- 3 - Contact -->
  <section class="contact-wrapper" :class="isActiveStep(3)">
    <pb-contact
      :accommodations="selectedAccommodations"
      :tourneys="selectedTourneys"
      :parties="selectedParties"
      :total_cost="total_cost"
      @changeStep="(i) => changeStep(i)"
      @close="(i) => submitForm(i)"
    />
  </section>

  <!-- 5 - Thanks -->
  <section class="thanks-wrapper" :class="isActiveStep(4)">
    <div style="margin-bottom: 2em;">
      <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Thank you for your inquiry</div>
      <p class="v4-base-paragraph color-storm" style="width: 450px; max-width: 100%;">In the meantime, feel free to email the Events Team with any questions.</p>
      <p class="v4-base-paragraph color-gradient-shark"><a href="mailto:events@bartonandgray.com">events@bartonandgray.com</a></p>
    </div>
    <div class="previous-step-button" @click="changeStep(0)" style="width: 300px; max-width: 100%;">
      <div class="v4-download-button bg-color-gradient-cloud">
        <div class="soft-square bg-color-gradient-sky">
          <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
        </div>
        <div class="button-label color-gradient-shark">RETURN TO OVERVIEW</div>
      </div>
    </div>
  </section>

</template>

<script setup>
  import { ref, toRaw } from 'vue'
  import PbOverview from './PbOverview.vue';
  import PbAccommodations from './PbAccommodations.vue';
  import PbActivities from './PbActivities.vue';
  import PbContact from './PbContact.vue';
  import PbFormTracker from './PbFormTracker.vue';

  const isDebug = false;

  let currentStep = ref(0);

  let selectedAccommodations = ref([]);
  let selectedTourneys = ref([]);
  let selectedParties = ref([]);
  let userData = ref({})

  let total_cost = ref(1050);

  function isActiveStep(i){
    if (i == currentStep.value) {
      return 'active';
    } else {
      return;
    }
  }

  function changePrice(){
    total_cost.value = 1050;
    if (selectedAccommodations.value.includes('White Elephant Palm Beach')) {
      total_cost.value += 1250 * 2; // This alone makes price to $4300
    } 
    
    if (selectedAccommodations.value.includes('The Breakers')){
      total_cost.value += 1625 * 2;  // This alone makes price to $3550
    }
  }

  function changeStep(i){
    //changePrice();    
    document.body.scrollTop = document.documentElement.scrollTop = 300;
    
    currentStep.value = i;
  }

  function submitForm(i){
    userData.value = i;
    currentStep.value += 1;
    sendEmail();
  }

  function sendEmail(){
    let endpoint = '/pb-escape-interest-post';    
    
    let data = {
      'selectedAccommodations': toRaw(selectedAccommodations.value),
      'selectedTourneys': toRaw(selectedTourneys.value),
      'selectedParties': toRaw(selectedParties.value),
      'userData': toRaw(userData.value)
    }

    const csrfToken = document.querySelector("[name='csrf-token']").content;

    let email_json = JSON.stringify({ selectedAccommodations: toRaw(selectedAccommodations.value), selectedTourneys: toRaw(selectedTourneys.value), selectedParties: toRaw(selectedParties.value), userData: userData.value })

    fetch(endpoint, {
      method: 'POST',
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        'Content-Type': 'application/json',
      },
      body: email_json
    }).then(response => response.json())
      .then(data => {
        console.log('Success: ', data);
      })
      .catch((e) => {
        console.log('Error: ', e, ' Data: ', data)
        console.log('testVar: ', testVar);
        
      })
  }

</script>

<style scoped lang="scss">
  section{
    &:not(.active){
      position: absolute;
      top: -9999px;
      left: -9999px;
      display: hidden;
    }
  }

  .thanks-wrapper{
    @media only screen and (max-width: 1280px){
      width: 440px;
      max-width: 100%;
      margin: auto;
      padding: 1em;
    }
  }
</style>