<template>
  <div class="layout-2col">
    <!-- LEFT -->
    <div class="col-left">
      <!-- Intro -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Contact</div>
        <p class="v4-base-paragraph color-storm" style="width: 450px; max-width: 100%;">Please tell us a little more about yourself so we can hold your spot at the 2025 Palm Beach Escape!</p>
      </div>

      <!-- Accommodations -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Accommodations</div>
        <p v-for="accommodation in accommodations" :key="accommodation" class="v4-type-paragraph italic color-storm" style="width: 450px; max-width: 100%; margin: .25em 0;">{{ accommodation }}</p>
        <p v-if="accommodations.includes('White Elephant Palm Beach')" class="v4-body-paragraph form-footnote color-gradient-shark" style="margin-top: 0.5em;">
          $1,250 / Night / Double Occupancy - White Elephant Palm Beach
        </p>
        <p v-if="accommodations.includes('The Breakers')" class="v4-body-paragraph form-footnote color-gradient-shark" style="margin-top: 0em; padding-top: 0;">
          $1,625 / Night / Double Occupancy - The Breakers
        </p>
      </div>

      <!-- The Tourneys -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">The Tourneys</div>
        <p v-for="tourney in tourneys" :key="tourney" class="v4-type-paragraph italic color-storm" style="width: 450px; max-width: 100%; margin: .25em 0;">{{ tourney }}</p>
        <p class="v4-body-paragraph form-footnote color-gradient-shark" style="margin-top: 0.5em;">
          $300 / Person
        </p>
      </div>

      <!-- Parties & Meals -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Socialize &amp; Celebrate</div>
        <p v-for="party in parties" :key="party" class="v4-type-paragraph italic color-storm" style="font-style: italic; width: 450px; max-width: 100%; margin: .25em 0;">{{ party }}</p>
        <p class="v4-body-paragraph form-footnote color-gradient-shark">
          All Included
        </p>
      </div>
      
      <!-- Convenience Fee -->
      <div style="margin-bottom: 2em;">
        <div class="v4-section-header-light color-gradient-shark" style="margin-bottom: 0.25em;">Convenience Fee</div>
        <p class="v4-type-paragraph color-storm" style="font-style: italic; width: 450px; max-width: 100%; margin: .25em 0;">
          Curation, hospitality and administrative services.
        </p>
        <p class="v4-body-paragraph form-footnote color-gradient-shark">
          $750 / Person
        </p>
      </div>

      <!-- Estimated Pricing -->
      <div style="margin-bottom: 2em;">
        <p class="v4-type-paragraph color-storm" style="font-style: italic; width: 500px; max-width: 100%; margin: .25em 0;">
          Please note: Not all meals included, and upgrade options may be available. Hotel taxes and fees not included.
        </p>
      </div>
    </div>

    <!-- RIGHT -->
    <div class="col-right">

      <p class="v4-subheader-nova required-note" style="font-size: 1.25em;">* Required</p>

      <!-- Fname -->
      <div class="contact-input">
        <div class="v4-base-header-nova"><span class="color-gradient-shark">First Name</span><span class="required-note">  *</span></div>
        <input class="input-option" v-model="fname" />
      </div>

      <!-- Lname -->
      <div class="contact-input">
        <div class="v4-base-header-nova"><span class="color-gradient-shark">Last Name</span><span class="required-note">  *</span></div>
        <input class="input-option" v-model="lname" />
      </div>

      <!-- Email -->
      <div class="contact-input" style="display: flex; flex-direction: column;">
        <div class="v4-base-header-nova"><span class="color-gradient-shark">Email</span><span class="required-note">  *</span></div>
        <input class="input-option" :class="validateEmail(email) ? 'valid' : 'invalid'" v-model="email" type="email" />
        <div v-if="!validateEmail(email)" style="color: red;">must be in email format</div>
      </div>

      <!-- Phone -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">Phone</div>
        <input class="input-option" v-model="phone" />
      </div>

      <!-- # of Guests -->
      <div class="contact-input">
        <div class="v4-base-header-nova"><span class="color-gradient-shark">Number of guests in your party</span><span class="required-note">  *</span></div>
        <select style="width:100px" class="input-list-dropdown hover-pointer" v-model="numGuests">
          <option v-for="index in 8" :key="index">{{ index }}</option>
        </select>
      </div>
      
      <!-- How did you hear -->
      <div class="contact-input">
        <div class="v4-base-header-nova color-gradient-shark">How did you hear about us?</div>
        <select style="width: 400px" class="input-list-dropdown hover-pointer" v-model="hearAbout">
          <option v-for="choice in hearAboutChoices" :key="choice">{{ choice }}</option>
        </select>
      </div>

      <!-- Button -->
      <div v-if="isReady()" @click="$emit('close', userData)" style="width: 300px; max-width: 100%; margin: 3em 0; padding-right: 1.5em">
        <div class="v4-download-button bg-color-gradient-cloud">
          <div class="soft-square bg-color-gradient-sky">
            <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
          </div>
          <div class="button-label color-gradient-shark">SUBMIT</div>
        </div>
      </div>

      
    </div>
  </div>
</template> 

<script setup>
import { ref } from 'vue';
const props = defineProps(['accommodations', 'tourneys', 'parties', 'total_cost'])
const accommodationsValue = ref(props.accommodations);
const tourneysValue = ref(props.tourneys);
const partiesValue = ref(props.parties);

const fname = ref('');
const lname = ref('');
const email = ref('');
const phone = ref('');
const numGuests = ref();
const hearAbout = ref('');
const hearAboutChoices = ref(['I am a B&G Member', 'From a B&G Member', 'From a friend', 'Newspaper / Magazine', 'Club / Resort', 'Blog, Google, Website']);

const userData = ref({fname, lname, email, phone, numGuests, hearAbout});

function validateEmail(email_text){
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email_text.match(mailformat) || email_text == '') {
    return true;
  } else {
    return false;
  } 
};

function isReady(){
  let flag = 0;

  if (!validateEmail(email.value)) {
    flag = 1;
  }

  // fname, lname, email, numguests required
  if((fname.value == '') || (lname.value == '') || (email.value == '') || (numGuests.value == null)){
    flag = 1;
  }

  if (flag == 0) {
    return true;
  } else {
    return false;
  }
}

</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/v3/abstracts/colors';

.previous-step-button{
  width: 400px;
  max-width: 100%;
  margin: 3em 0;
  margin-right: auto;
  padding-right: 1.5em;

  @media only screen and (max-width: 1280px){
    margin-left: 1em;
  }
}

.legend-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1em;
  .legend-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
}

.checkmark{
  path{
    fill: $v3-french-blue;
  }
  &.selected{
    path{
      fill: $v3-port;
    }
  }
}

.contact-input{
  margin: 1em 0;
  margin-bottom: 1.5em;
  .v4-base-header-nova{
    margin-bottom: 0.25em;
  }
}

.input-option{
  background: $gradient-cloud !important;
  border: 1px dashed black;
  width: 400px;
  max-width: 100%;
  padding: 0.75em 0 0.75em 1em;
  transition: 0.15s ease-in-out;
  &:hover{
    filter: brightness(90%);
  }
  &.selected{
    path{
      fill: $v3-port;
    }
  }
  &.invalid{
    border: 2px solid red;
    &:focus{
      outline: none;
    }
  }
}

.input-list-dropdown{
  max-width: 100%;
  height: 30px;
  border: 1px dashed black;
  background: $gradient-cloud !important;
}

.form-footnote{
  margin-top: 0.25em;
}

.required-note{
  color: $v3-reserved !important;
}

</style>